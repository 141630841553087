.domain {
  padding: 1rem 2rem;
  box-sizing: border-box;
  border: 1px solid rgba(68, 60, 104, 0.3);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  height: 100%;
  max-width: 95%;
}

.top-bar {
  display: flex;
  width: 100%;
  /* align-items: space-between; */
  justify-content: space-between;
}

.color-button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #443c68;
  color: white;
  font-size: 18px;
  padding: 0.7rem 3rem;
  margin-left: 1rem;
  font-family: "DM Sans";
  cursor: pointer;
}

.domain-table {
  overflow-x: scroll;
  height: 100%;
}

.domain-table table {
  width: 100%;
  font-family: "DM Sans";
  border-collapse: collapse;
  /* border-spacing: 1rem; */
}

.domain-table tr th {
  padding: 1rem;
}

.domain-table tr td {
  padding: 0.5rem 1rem;
  text-align: center;
  /* font-weight: bold; */
  color: #7d7d7d;
}

.domain-table thead {
  background: rgba(68, 60, 104, 0.07);
  color: #1c004e;
}

/* .domain-table td {
} */

.domain-table a {
  display: block;
  width: 50px;
  height: 50px;
  margin: auto;
}

.domain-table a img {
  width: 100%;
}

.table-text {
  color: #7d7d7d;
}

.setting {
  color: #393053;
  cursor: pointer;
}

/* Input Checkbox CSS */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  /* background-color: #2196f3; */
  /* background: linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%); */
  background: linear-gradient(136.37deg, #393053 -2.34%, #635985 105.08%);
}

input:focus + .slider {
  /* box-shadow: 0 0 1px linear-gradient(178.89deg, #00505d 0.95%, #52bd94 101.59%); */
  box-shadow: 0 0 1px
    linear-gradient(136.37deg, #393053 -2.34%, #635985 105.08%);
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 800px) {
  .domain {
    width: 95%;
  }
}

@media screen and (max-width: 500px) {
  .domain {
    padding: 1rem 1rem;
  }

  .top-bar {
    flex-direction: column;
    /* align-items: center; */
    row-gap: 1rem;
  }

  .top-bar > * {
    width: 100% !important;
  }

  .color-button {
    margin-left: 0;
  }

  .domain-table {
    overflow-x: scroll;
  }
}
