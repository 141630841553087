@import url("https://fonts.googleapis.com/css2?family=DM+Sans&display=swap");

/* Global Buttons */
.color-button {
  border: 1px solid #ffffff;
  border-radius: 8px;
  background-color: #443c68;
  color: white;
  font-size: 18px;
  padding: 0.7rem 1.5rem;
  font-family: "DM Sans";
  cursor: pointer;
}

.color-inverse-button {
  border-radius: 8px;
  color: #00505d;
  background-color: white;
  font-size: 18px;
  border: none;
  padding: 0.7rem 1.5rem;
  margin-left: 1rem;
  font-family: "DM Sans";
  border: 1px solid #443c68;
  cursor: pointer;
}

/* Pagination */
.pagination-list {
  list-style-type: none;
  display: flex;
  margin: auto;
  column-gap: 1rem;
  margin-bottom: 2rem;
}

.pagination-list li {
  cursor: pointer;
}

/* MUI Global Styling */
.MuiOutlinedInput-input {
  padding: 0.8rem 1rem !important;
}

.MuiInputAdornment-root {
  color: #00505d !important;
}

.MuiInputAdornment-root > p {
  color: #00505d !important;
}

.MuiInputAdornment-positionEnd {
  width: min-content !important;
}

/* PhoneInput */
.PhoneInput {
  flex-direction: row-reverse;
  column-gap: 20px;
}

.PhoneInput input {
  font-size: 1rem;
  padding: 0.8rem 1rem !important;
  border: 1px rgba(0, 0, 0, 0.23) solid;
  border-radius: 4px;
}

/* scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 255, 255, 0.3);
}
